import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../httpCommon.js";
import useEmailValidation from "../utils/useEmailValidation.js";
import "../css/feedback.css";

const Feedback = () => {
  const navigate = useNavigate();

  const [BuzDetails, setBuzDetails] = useState([]);
  const [name, setName] = useState("");
  const { email, emailError, onEmailChange , setEmailError, validateEmail} = useEmailValidation("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setCurrentValue] = useState("1");

  const handleRadioChange = (e) => {
    const rateVal = e.target.value;
    setCurrentValue(rateVal);
  };


  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");

  useEffect(() => {
    const payload = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosBaseURL
      .get(`/qrcode/getBuzDetailsById/${code}`, payload)
      .then((response) => {
        setBuzDetails(response.data.data);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (email && validateEmail(email) && !emailError) {
      const payload = {
        userId: code,
        rating_one: rating,
        commentText: description,
        name: name,
        email: email,
        phone: mobileNumber,
      };
      
      try {
        const response = await axiosBaseURL.post(`/qrcode/feedback`, payload);
        console.log(response);
        if (response.status === 200) {
          navigate("/SuccessPage", { state: BuzDetails }); // Redirect to new page
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setEmailError("Email is required.");
    }
  };

  return (
    <>
      <div class="business-feedback">
        <span>
          <h4>Business Ratings </h4>
          <br />
        </span>

        <section>
          <div class="rt-container">
            <div class="col-rt-12">
              <div class="feedback">
                <p class="txtclr">
                  Dear Customer,
                  <br />
                  <br />
                  Thank you for visiting{" "}
                  <u>
                    <b>{BuzDetails.businessName}</b>,
                  </u>{" "}
                  our{" "}
                  <u>
                    <b>{BuzDetails.clubName}</b>
                  </u>{" "}
                  Business. We appreciate your patronage.
                  <br />
                  Please take a moment to tell us how we did and/or how we can
                  improve!
                  <br />
                  <br />
                  Required fields are marked with *
                </p>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label class="txtclr">Overall Rating*</label>
                    <br />

                    <span class="star-rating">
                      <input
                        type="radio"
                        formControlName="rating_one"
                        name="rating"
                        value="1"
                        onChange={handleRadioChange}
                        checked={rating === "1"}
                      />
                      <i></i>
                      <input
                        type="radio"
                        formControlName="rating_one"
                        name="rating"
                        value="2"
                        onChange={handleRadioChange}
                      />
                      <i></i>
                      <input
                        type="radio"
                        formControlName="rating_one"
                        name="rating"
                        value="3"
                        onChange={handleRadioChange}
                      />
                      <i></i>
                      <input
                        type="radio"
                        formControlName="rating_one"
                        name="rating"
                        value="4"
                        onChange={handleRadioChange}
                      />
                      <i></i>
                      <input
                        type="radio"
                        formControlName="rating_one"
                        name="rating"
                        value="5"
                        onChange={handleRadioChange}
                      />
                      <i></i>
                    </span>
                  </div>

                  <div class="clear"></div>
                  <label for="commentText" class="txtclr">
                    Review Comments
                  </label>
                  <br />

                  <textarea
                    cols="60"
                    formControlName="commentText"
                    name="description"
                    value={description}
                    placeholder="Comments"
                    rows="5"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>

                  <div class="form-group row" style={{ marginTop: "15px" }}>
                    <label for="Name" class="col-sm-2 col-form-label">
                      Your Name
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        formControlName="name"
                        name="name"
                        class="input-cls"
                        value={name}
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-sm-2 col-form-label">
                      Email ID*
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="email"
                        formControlName="email"
                        name="email"
                        class="input-cls"
                        value={email}
                        placeholder="Email"
                        onChange={onEmailChange}
                      />
                      {emailError && <p>{emailError}</p>}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-sm-2 col-form-label">
                      Mobile No
                    </label>
                    <div class="col-sm-10">
                      <input
                        type="number"
                        formControlName="phone"
                        name="mobileNumber"
                        value={mobileNumber}
                        placeholder="Mobile Number"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        class="input-cls"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-sm-2 col-form-label"></label>
                    <div class="col-sm-10">
                      <input
                        class="btn btn-primary btn-lg"
                        style={{ color: "#fff", backgroundColor: "#E02473" }}
                        type="submit"
                    
                        value="Submit Rating"
                      />
                      &nbsp;
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- scroll to top --> */}

      <div class="progress-wrap">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  );
};

export default Feedback;