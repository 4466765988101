import React, { useState, useEffect } from 'react';
import axiosBaseURL from '../httpCommon.js';
//import InfiniteScroll from "react-infinite-scroll-component";
import '../css/post-style.css';

const PostsPage = () => {

  //const apiUrl = process.env.REACT_APP_API_URL;
  const [posts, setPosts] = useState([]);
  const [BusinessPosts, setBusinessPosts] = useState([]);
  const [pincode, setPincode] = useState('');

  const fetchData = async () => {

    const payload = {
      params: {
        pincode: pincode,
        limit: 15,
        offset: 0,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axiosBaseURL
      .get(`/posts/membersPosts`, payload)
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      
    await axiosBaseURL
      .get(`/posts/businessPosts`, payload)
      .then((response) => {
        setBusinessPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []); 

  let handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };


  return (
    <>


      {/* ==== header start ==== */}
      <header class="header" style={{ position: `sticky`, top: `0` }}>
        <div class="container" >
          <div class="row" >
            <div class="col-lg-12">
              <nav class="nav" >
                <div class="nav__content" >
                  <div class="nav__logo">
                    <a href="/">
                      <img src={require('../images/logo.svg').default} alt="" />
                    </a>

                  </div>
                  <div class="nav__menu">
                    <div class="nav__menu-logo d-flex d-xl-none">
                      <a href="/" class="text-center hide-nav">

                        <img src={require('../images/logo.svg').default} alt="" />
                      </a>
                      <a href="/" class="nav__menu-close">
                        <i class="fa-solid fa-xmark"></i>
                      </a>
                    </div>
                    <ul class="nav__menu-items">
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">HOME</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">ABOUT US</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">HOW WE WORK</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">KEY FEATURES</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/PrivacyPolicy">TRUST & SAFETY</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">OUR TEAM</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">CONTACT US</a>
                      </li>
                    </ul>

                  </div>
                  <div class="nav__uncollapsed">
                    <div class="nav__uncollapsed-item d-none d-md-flex">
                      <a href="#" class=" "><img src={require('../images/playstore.svg').default} alt="Cling Play Store" /></a>
                    </div>
                    <button class="nav__bar d-block d-xl-none">
                      <span class="icon-bar top-bar"></span>
                      <span class="icon-bar middle-bar"></span>
                      <span class="icon-bar bottom-bar"></span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="backdrop"></div>
      </header>



      <div class="row left-panel" style={{ color: '#fff' }}>
        <div class="col-sm-12 col-md-3" style={{ marginLeft: '5%' }}>
          <h1 style={{ fontSize: '30px', color: '#000' }}>
            Engage local.
            <br />
            Reach beyond..
          </h1>
          <hr class="hr-border" />
          <hr class="hr-border2" />
          <div class="row" >
            <div class="col-5">
              <img src={require('../assets/images/favicon.png')} height="auto" alt='' />
            </div>
            <div class="col-6" >
              <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Pin Code" value={pincode} class="form-control" onChange={(e) => setPincode(e.target.value)} />
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <input type="submit" style={{ margin: 'auto 0px', color: '#fff' }} value="Get Started" class="btn btn-primary" />
                </div>
              </form>
            </div>
          </div>
          <br />
          <div class="qrdiv">
            <div class="text-center">
              <a href="/">Download The App</a>
              <br />
              <img src={require('../assets/imagesPosts/sampleqr.jpg')} style={{ width: '130px' }} alt='' />
            </div>
            <div class="row">
              <div class="col-6">
                <img src={require('../assets/imagesPosts/googleplay.jpg')} style={{ width: '100%', height: '55px' }} alt='' />
              </div>
              <div class="col-6">
                <img src={require('../assets/imagesPosts/applestore.png')} style={{ width: '100%', height: '55px' }} alt='' />
              </div>
            </div>
          </div>
        </div>


        <div class="col-1 col-sm-1"></div>
        <div class="col-sm-12 col-md-7" >
          
          <div class="row" >
            <div class="col-sm-12 col-md-6 cntdiv" >
              <div class="text-center"><span style={{ fontSize: '25px', color: '#000', fontWeight: '500' }}>
                Club Members</span></div>

                
              <div >
              
                {posts && posts.map((post) => {
                  return (
                    <>
                      <div class="es-box" >
                        <div class="es-user mb-20">
                          <div class="es-user-details ">

                            <div class="es-username">{post.firstName}
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                            </div>
                            <div class="es-date">{post.createdAt}</div>

                          </div>
                          <div class="es-logo">
                          </div>
                        </div>
                        <div class="es-media">

                          <div class="es-photo">

                            <div class="slide">
                              <img src={post.file_path + post.postfiles[0].filename} style={{ width: '100%', height: 'auto' }} alt='' />
                            </div>

                          </div>

                        </div>
                        <div class="es-text mb-0" title="Yellow is so IN!">{post.title}</div>
                      </div>
                    </>
                  );
                })}
                
                
              </div>
              
            </div>
            <div class="col-sm-12  col-md-6 cntdiv" >
              <div class="text-center"><span style={{ fontSize: '25px', color: '#000', fontWeight: '500' }}>
                Club Businesses</span>
              </div>
              {BusinessPosts.map((bpost) => {
                return (
                  <>
                    <div class="es-box" >
                      <div class="es-user mb-20">
                        <div class="es-user-details ">

                          <div class="es-username">{bpost.firstName}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                          <div class="es-date">{bpost.createdAt}</div>

                        </div>
                        <div class="es-logo">
                        </div>
                      </div>
                      <div class="es-media">

                        <div class="es-photo">

                          <div class="slide">
                            <img src={bpost.file_path + bpost.postfiles[0].filename} style={{ width: '100%', height: 'auto' }} alt='' />
                          </div>

                        </div>

                      </div>
                      <div class="es-text mb-0" title="Yellow is so IN!">{bpost.title}</div>
                    </div>
                  </>
                );
              })}
            </div>
          </div >
        </div >
      </div >
      {/* <!-- scroll to top --> */}

      <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  );
};

export default PostsPage;