// useEmailValidation.js
import { useState } from "react";

const useEmailValidation = (initialValue) => {
  const [email, setEmail] = useState(initialValue);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (emailValue) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailValue);
  };

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (value == null || value === "") {
      setEmailError("Email is required.");
    } else if (!validateEmail(value)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError(""); // No error
    }
  };

  return { email, emailError, onEmailChange , setEmailError, validateEmail};
};

export default useEmailValidation;